.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html,
body{
  height:100%;
}

#root{
  /*align-items:center;*/
  display:flex;
  height:100%;
  justify-content:center;
}

.board{
  border:solid 1px #000;
  /*display:grid;*/
  /*grid-template-columns:auto auto auto;*/
  height:400px;
  margin:3rem auto;
  position:relative;
  width:400px;
}

.square{
  /*background-image:url('https://picsum.photos/400/400');*/
  background-image:url('./img/slider.jpg');
  border:solid 1px #000;
  border-style:solid;
  border-width:1px;
  cursor:pointer;
  height:100px;
  width:100px;
  transition:.5s;
}
